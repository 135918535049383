input[type='file'] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}

input[type='file']:hover {
  cursor: pointer;
}

img {
  height: 200px;
}
