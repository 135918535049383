  .dialog {
    box-shadow: 0 8px 6px -6px black;
    z-index: 17;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .image {
    max-height: 800px;
    max-width: 800px;
  }
  