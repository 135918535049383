.bulkSuspendCheckBoxActive {
  animation-name: fadeInFromRight;
  animation-duration: 0.5s;
}

.bulkSuspendCheckBoxfade {
  animation-name: fadeOutToRight;
  animation-duration: 0.5s;
}

.suspendActive {
  animation-name: fadeInFromRight;
  text-align: center;
  animation-duration: 0.5s;
}

.suspendFade {
  animation-name: fadeOutToRight;
  text-align: center;
  animation-duration: 0.5s;
}

.cellInner {
  display: flex;
  justify-content: center;
}

.tableHeader {
  overflow: hidden;
}

@keyframes fadeInFromRight {
  from {
    margin-right: -120px;
  }
  to {
    margin-right: 0px;
  }
}

@keyframes fadeOutToRight {
  from {
    margin-right: 0px;
  }
  to {
    margin-right: -120px;
  }
}
