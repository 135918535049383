.select {
  display: grid;
  grid-template-areas: 'select';
  align-items: center;
  position: relative;
  min-width: 63ch;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
  font-size: 1.25rem;
  cursor: pointer;
  background-color: #f1f1f1;
  border: solid 1px #b9bbbd;
}

.not-allowed-select-wrapper {
  cursor: not-allowed;
}
